import { AGREGAR_BENEFICIO_A_FAVORITOS, AGREGAR_BENEFICIO_A_FAVORITOS_ERROR, AGREGAR_SUCURSAL_A_FAVORITOS, AGREGAR_SUCURSAL_A_FAVORITOS_ERROR, CREAR_CUPON, CREAR_CUPON_ERROR, ELIMINAR_BENEFICIO_A_FAVORITOS, ELIMINAR_BENEFICIO_A_FAVORITOS_ERROR, ELIMINAR_SUCURSAL_A_FAVORITOS, MENSAJE_ERROR, OBTENER_BENEFICIO, OBTENER_BENEFICIOS, OBTENER_BENEFICIOS_CATEGORIA, OBTENER_BENEFICIOS_CATEGORIA_ERROR, OBTENER_BENEFICIOS_ERROR, OBTENER_BENEFICIOS_EXCLUSIVOS, OBTENER_BENEFICIOS_EXCLUSIVOS_ERROR, OBTENER_BENEFICIOS_EXCLUSIVOS_GEO, OBTENER_BENEFICIOS_EXCLUSIVOS_GEO_ERROR, OBTENER_BENEFICIOS_FAVORITOS, OBTENER_BENEFICIOS_FAVORITOS_ERROR, OBTENER_BENEFICIO_ERROR, OBTENER_CUPONES, OBTENER_CUPONES_ERROR, RESETEAR_CARGANDO, RESETEAR_CREADO, RESETEAR_MENSAJE, BENEFICIOS_BUSCADOS, OBTENER_BENEFICIOS_PROVINCIA, OBTENER_BENEFICIOS_PROVINCIA_GEO } from '../../types'

const beneficiosReducer = (state, action) => {
    switch (action.type) {
        case OBTENER_BENEFICIOS_ERROR:
        case OBTENER_BENEFICIO_ERROR:
        case OBTENER_CUPONES_ERROR:
        case OBTENER_BENEFICIOS_CATEGORIA_ERROR:
        case OBTENER_BENEFICIOS_FAVORITOS_ERROR:
        case AGREGAR_BENEFICIO_A_FAVORITOS_ERROR:
        case ELIMINAR_BENEFICIO_A_FAVORITOS_ERROR:
        case OBTENER_BENEFICIOS_EXCLUSIVOS_ERROR:
        case OBTENER_BENEFICIOS_EXCLUSIVOS_GEO_ERROR:
        case CREAR_CUPON_ERROR:
        case MENSAJE_ERROR:
            return {
                ...state,
                mensajeBeneficios: action.payload,
                redirect: false,
                cargandoBeneficios: false,
            }
        case OBTENER_BENEFICIOS:
            return {
                ...state,
                beneficios: action.payload,
                categorias: action.categorias,
                cargandoBeneficios: false,
            }
        case OBTENER_BENEFICIOS_CATEGORIA:
            return {
                ...state,
                beneficiosPorCategoria: action.payload.listaSucursalesBeneficios,
                beneficiosMarcas: action.payload.listaMarca,
                beneficiosDescuentos: action.payload.listaDescuentos,
                beneficiosCategorias: action.payload.listaCategorias,
                beneficiosMonedas: action.payload.listaMonedas,
                cargandoBeneficios: false,
            }
        case OBTENER_BENEFICIOS_EXCLUSIVOS:
            return {
                ...state,
                beneficiosExclusivos: action.payload,
                cargandoBeneficios: false,
            }
        case OBTENER_BENEFICIOS_EXCLUSIVOS_GEO:
            return {
                ...state,
                beneficiosPorCategoria: action.payload.listaSucursalesBeneficios,
                beneficiosMarcas: action.payload.listaMarca,
                beneficiosDescuentos: action.payload.listaDescuentos,
                beneficiosCategorias: action.payload.listaCategorias,
                beneficiosMonedas: action.payload.listaMonedas,
                cargandoBeneficios: false,
            }
        case OBTENER_BENEFICIOS_FAVORITOS:
            return {
                ...state,
                beneficiosPorCategoria: action.payload.listaSucursalesBeneficios,
                beneficiosMarcas: action.payload.listaMarca,
                beneficiosDescuentos: action.payload.listaDescuentos,
                beneficiosCategorias: action.payload.listaCategorias,
                beneficiosMonedas: action.payload.listaMonedas,
                cargandoBeneficios: false,
            }
        case OBTENER_BENEFICIOS_PROVINCIA:
            return {
                ...state,
                beneficiosProvincia: action.payload,
                cargandoBeneficios: false,
            }
        case OBTENER_BENEFICIOS_PROVINCIA_GEO:
            return {
                ...state,
                beneficiosPorCategoria: action.payload.listaSucursalesBeneficios,
                beneficiosMarcas: action.payload.listaMarca,
                beneficiosDescuentos: action.payload.listaDescuentos,
                beneficiosCategorias: action.payload.listaCategorias,
                beneficiosMonedas: action.payload.listaMonedas,
                cargandoBeneficios: false,
            }
        case BENEFICIOS_BUSCADOS:
            return {
                ...state,
                beneficiosBuscados: action.payload.listaSucursalesBeneficios,
                beneficiosMarcas: action.payload.listaMarca,
                beneficiosDescuentos: action.payload.listaDescuentos,
                beneficiosCategorias: action.payload.listaCategorias,
                beneficiosMonedas: action.payload.listaMonedas,
                cargandoBeneficios: false
            }
        case CREAR_CUPON:
            return {
                ...state,
                cargandoBeneficios: false,
                cupon: action.payload,
                creado: true,
            }
        case AGREGAR_BENEFICIO_A_FAVORITOS:
        case ELIMINAR_BENEFICIO_A_FAVORITOS:
            return {
                ...state,
                cargandoBeneficios: false,
                beneficio: { ...state.beneficio, "esFavorito": action.payload }
            }
        case AGREGAR_SUCURSAL_A_FAVORITOS:
        case ELIMINAR_SUCURSAL_A_FAVORITOS:
            return {
                ...state,
                cargandoBeneficios: false,
                beneficiosPorCategoria: state.beneficiosPorCategoria.map((beneficio) => beneficio.idBeneficio == action.payload.id ?
                    { ...beneficio, esFavorito: action.payload.esFavorito }
                    :
                    { ...beneficio }
                )
            }
        case OBTENER_CUPONES:
            return {
                ...state,
                cargandoBeneficios: false,
                cupones: action.payload
            }
        case OBTENER_BENEFICIO:
            return {
                ...state,
                cargandoBeneficios: false,
                beneficio: action.payload,
            }
        case RESETEAR_CARGANDO:
            return {
                ...state,
                cargandoBeneficios: true,
            }
        case RESETEAR_MENSAJE:
            return {
                ...state,
                mensajeBeneficios: null
            }
        case RESETEAR_CREADO:
            return {
                ...state,
                creado: false,
            }
        default:
            return state;

    }
}

export default beneficiosReducer