import { Link, useNavigate } from 'react-router-dom';
import Background from '../layout/Background';
import Carousel from 'react-bootstrap/Carousel';


const CuponesOnboarding = () => {

    const navigate = useNavigate();

    return (
        <Background>
            <div className="d-flex flex-column min-vh-100 w-100">
                <div className="d-flex flex-column bg-white-rounded justify-content-center flex-fill align-self-center">
                    <div className="my-auto mx-auto">
                        <img
                            src={`${window.location.origin}/img/logo-axion.svg`}
                            className="d-block w-100"
                            alt="Logo de AXION Energy"
                            width="90px"
                            height="89px" />
                    </div>
                    <div className="my-auto px-2">
                        <Carousel className='text-center'>
                            <Carousel.Item className='' interval={8000}>
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    <img src={`${window.location.origin}/img/cupones/Ilustracion_1.svg`}></img>
                                    <div className="ml-3">
                                        <p className="fs-18 mt-1">En ON cuanto más cargas, más ahorras. El tope de reintegro se personaliza de acuerdo a su consumo.</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item className='' interval={8000}>
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    <img src={`${window.location.origin}/img/cupones/Ilustracion_2.svg`}></img>
                                    <div className="ml-3 mt-1">
                                        <p className="fs-18">Se calcula en base al promedio de litros de tus últimas 5 cargas</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item className='' interval={8000}>
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    <img src={`${window.location.origin}/img/cupones/Ilustracion_3.svg`}></img>
                                    <div className="ml-3 mt-1">
                                        <p className="fs-18">Si en promedio cargaste 20 litros tu ahorro será sobre esa cantidad.</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <div className='mt-auto d-grid gap-2'>
                        <Link to={"/cupones"}><button className="btn btn-azul fs-16-bold w-100">Ir a mis cupones</button></Link>
                        <Link to={('/home')} className="btn text-fucsia fs-16-bold" style={{ textDecoration: 'none', color: '#EC008B', fontWeight: 'bold', cursor: 'pointer' }}> Salir </Link>
                    </div>


                </div>

            </div>
        </Background>)

}

export default CuponesOnboarding;