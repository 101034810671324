import {staticOpenNewWindow} from './utils';
import { WebSocialShareEmailAttributes } from '../types-redes-sociales/web-social-share-attributes';


export const email = async (attrs) => {
  let urlString = 'mailto:';

  if (attrs.socialShareTo) {
    urlString += encodeURIComponent(attrs.socialShareTo);
  }

  urlString += '?';

  if (attrs.socialShareBody) {
    urlString += 'body=' + encodeURIComponent(attrs.socialShareBody);
  }

  if (attrs.socialShareSubject) {
    urlString += '&subject=' + encodeURIComponent(attrs.socialShareSubject);
  }

  if (attrs.socialShareCc) {
    urlString += '&cc=' + encodeURIComponent(attrs.socialShareCc);
  }

  if (attrs.socialShareBcc) {
    urlString += '&bcc=' + encodeURIComponent(attrs.socialShareBcc);
  }

  staticOpenNewWindow(urlString);
};
