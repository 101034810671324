import React, { useEffect, useRef } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import Onboarding from "../components/layout/Onboarding";
import { useContext } from "react";
import usuarioContext from "../context/usuario/usuarioContext";
import clienteAxios from "./axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = (props) => {

    const { obtenerNivel, contacto } = useContext(usuarioContext);

    const navigate = useNavigate();

    let isMounted = useRef(true);
    let delay = 60000;

    let myTimeout = null;

    function onVisibilityChanged() {
        if (document.hidden || document.mozHidden || document.webkitHidden || document.msHidden) {
            clearTimeout(myTimeout)
        } else {
            myTimeout = setTimeout(startInterval, delay)
        }
    }

    const startInterval = () => {
        obtenerNivel();
        myTimeout = setTimeout(startInterval, delay);
    }

    useEffect(() => {

        if (isMounted.current) {
            startInterval();
            document.addEventListener("visibilitychange", onVisibilityChanged, false);

            fetchTyCAndLogin();
        }

        return () => {
            isMounted.current = false
        }
    }, [contacto])

    const fetchTyCAndLogin = async () => {
        try {

            const res = await clienteAxios.get('checktycmodified');

            if (res.data.response) {
                Swal.fire({
                    html: `
                    <div class="d-flex flex-column gap-3">
                        <p class="fs-20-bold text-rosa">Actualizamos la página de Términos y Condiciones</p>
                        <img class="align-self-center" width="180px" src="${window.location.origin}/img/popups/terminos-y-condiciones.svg"/>
                        <p class="fs-14-400 text-gris">Te invitamos a revisar los nuevos cambios para seguir disfrutando de nuestra app. ¡Gracias por tu comprensión!</p>
                    </div>
                    `,
                    confirmButtonText: 'Ver los Términos y Condiciones',
                    showCloseButton: true,
                    closeButtonHtml: `
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.2095 1.70711C18.6001 1.31658 18.6001 0.683417 18.2095 0.292893C17.819 -0.0976311 17.1859 -0.0976311 16.7953 0.292893L9.50122 7.587L2.20711 0.292893C1.81658 -0.0976311 1.18342 -0.0976311 0.792893 0.292893C0.402369 0.683417 0.402369 1.31658 0.792893 1.70711L8.087 9.00122L0.792893 16.2953C0.402369 16.6859 0.402369 17.319 0.792893 17.7095C1.18342 18.1001 1.81658 18.1001 2.20711 17.7095L9.50122 10.4154L16.7953 17.7095C17.1859 18.1001 17.819 18.1001 18.2095 17.7095C18.6001 17.319 18.6001 16.6859 18.2095 16.2953L10.9154 9.00122L18.2095 1.70711Z" fill="#8F8E8E" />
                    </svg>
                    `
                }).then(async (res) => {
                    if (res.isConfirmed) {
                        navigate("/legales");
                    }
                })
            }

            await clienteAxios.get('/logincheck');

        } catch {
            return;
        }
    }

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            errorComponent={<Onboarding />}
        >
            {props.children}
        </MsalAuthenticationTemplate>
    );
};

export default ProtectedRoute;