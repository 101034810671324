import {MENSAJE_ERROR, OBTENER_CATEGORIASFAQ, OBTENER_NOVEDAD, OBTENER_NOVEDADES, RESETEAR_CARGANDO, RESETEAR_MENSAJE } from "../../types";


export default (state, action) => {
    switch (action.type) {
        case MENSAJE_ERROR:
            return {
                ...state,
                mensajeNovedades: action.payload,
                novedad: null,
                cargandoNovedades: false,
            }
        case OBTENER_NOVEDAD:
            return {
                ...state,
                novedad: action.payload,
                cargandoNovedades: false
            }
        case OBTENER_NOVEDADES:
            return {
                ...state,
                novedades: action.payload,
                cargandoNovedades: false
            }
        case RESETEAR_MENSAJE:
            return {
                ...state,
                mensajeNovedades: null,
            }
        case RESETEAR_CARGANDO:
            return {
                ...state,
                cargandoNovedades: true,
            }
        default:
            return state;

    }
}