import React, { useContext, useReducer } from "react";
import clienteAxios from "../../config/axios";
import preferenciasContext from "./preferenciasContext";
import preferenciasReducer from "./preferenciasReducer";
import { useEffect } from "react";
import { GET_CATEGORIAS, GET_INTERESES, MENSAJE_USUARIO_ERROR, RESETEAR_CARGANDO, UPDATE_CATEGORIAS, UPDATE_INTERES } from "../../types";
import usuarioContext from "../usuario/usuarioContext";


const PreferenciasState = props => {
    const initialState = {
        intereses: [],
        categorias: [],
        loadingPreferencias: false
    }

    const [state, dispatch] = useReducer(preferenciasReducer, initialState);

    const { obtenerUsuario} = useContext(usuarioContext);

    useEffect(() => {

        clienteAxios.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                const originalConfig = error.config;
                if (error.response) {
                    if (error.response.status === 401) {
                        window.location.replace("/");
                    }
                }
                return Promise.reject(error);
            }
        );
    }, [])

    const getIntereses = async (id) => {
        try {
            resetearCargando();
            const respuesta = await clienteAxios.get('preferencias/intereses/' + id);

            dispatch({
                type: GET_INTERESES,
                payload: respuesta.data.response,
            })

        } catch (err) {
            enviarError(err);
        }
    }

    const getCategorias = async () => {
        try {
            resetearCargando();
            const respuesta = await clienteAxios.get('preferencias/categorias/');

            dispatch({
                type: GET_CATEGORIAS,
                payload: respuesta.data.response,
            })

        } catch (err) {
            enviarError(err);
        }
    }

    const updateInteres = async (data) => {
        try {
            resetearCargando();
            const respuesta = await clienteAxios.post('preferencias/intereses/', data);

            dispatch({
                type: UPDATE_INTERES,
                payload: respuesta.data.response,
            })

            obtenerUsuario();

        } catch (err) {
            enviarError(err);
        }
    }

    const updateCategorias = async (data) => {
        try {
            resetearCargando();
            const respuesta = await clienteAxios.post('preferencias/categorias/', data);

            dispatch({
                type: UPDATE_CATEGORIAS,
                payload: respuesta.data.response,
            })

            obtenerUsuario();

        } catch (err) {
            enviarError(err);
        }
    }

    const enviarError = (err) => {
        var error = null;
        if (err.response !== undefined) error = err.response;
        if (err.data !== undefined) error = err;
        const alerta = {
            msg: error !== undefined ? error.data.message : "Este servicio no está disponible, por favor inténtelo de nuevo más tarde",
            categoria: error !== undefined ? error.data.type : "error",
        }
        dispatch({
            type: MENSAJE_USUARIO_ERROR,
            payload: alerta,
        })
    }

    const resetearCargando = () => {
        dispatch({
            type: RESETEAR_CARGANDO,
        })
    }

    return (
        <preferenciasContext.Provider
            value={{
                intereses: state.intereses,
                categorias: state.categorias,
                loadingPreferencias: state.loadingPreferencias,
                getIntereses,
                getCategorias,
                updateInteres,
                updateCategorias,
                resetearCargando
            }}>
            {props.children}
        </preferenciasContext.Provider>
    )
}

export default PreferenciasState;


