import axios from 'axios'

const token = localStorage.getItem('token')
const baseURL = process.env.REACT_APP_API_ENDPOINT
const apimHeader = process.env.REACT_APP_APIM_HEADER
const apimKey = process.env.REACT_APP_APIM_KEY

const clienteAxios = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + (token === null ? '' : token),
        [apimHeader]: apimKey,
    }
})

export default clienteAxios;