import React, { useReducer } from "react";
import clienteAxios from "../../config/axios";
import { MENSAJE_ERROR, OBTENER_NOVEDAD, OBTENER_NOVEDADES, RESETEAR_CARGANDO, RESETEAR_MENSAJE } from "../../types";
import novedadesContext from "./novedadesContext";
import novedadesReducer from "./novedadesReducer";


const NovedadesState = props => {
    const initialState = {
        mensajeNovedades: null,
        novedades: null,
        novedad: null,
        cargandoNovedades: true,
    }

    const [state, dispatch] = useReducer(novedadesReducer, initialState);

    const obtenerNovedades = async () => {
        resetearCargando();
        try {

            const respuesta = await clienteAxios.get('getnovedades')
            if(respuesta.data?.statusCode==206){
                enviarError(respuesta);  
            }
            else{
                dispatch({
                    type: OBTENER_NOVEDADES,
                    payload: respuesta.data.response,
                })
            }

        } catch (err) {
            enviarError(err);
        }
    }

    const obtenerNovedad = async (id) => {
        try {

            resetearCargando();
            const respuesta = await clienteAxios.get('getnovedadbyid/' + id);
            if(respuesta.data?.statusCode==206){
                enviarError(respuesta);  
            }
            else{
                dispatch({
                    type: OBTENER_NOVEDAD,
                    payload: respuesta.data.response,
                })
            }
        } catch (err) {
            enviarError(err);
        }
    }

    const enviarError = (err) => {
        var error=null;
        if(err.response!==undefined) error = err.response;
        if(err.data!==undefined) error = err;

        const alerta = {
            msg: error !== undefined ? error.data.message : "Este servicio no está disponible, por favor inténtelo de nuevo más tarde",
            categoria: error !== undefined ? error.data.type : "error",
        }
        dispatch({
            type: MENSAJE_ERROR,
            payload: alerta,
        })
    }

    const resetearMensajeNovedades = () => {
        dispatch({
            type: RESETEAR_MENSAJE,
        })
    }

    const resetearCargando = () => {
        dispatch({
            type: RESETEAR_CARGANDO,
        })
    }

    return (
        <novedadesContext.Provider
            value={{
                novedades: state.novedades,
                novedad: state.novedad,
                mensajeNovedades: state.mensajeNovedades,
                cargandoNovedades: state.cargandoNovedades,
                obtenerNovedad,
                obtenerNovedades,
                resetearMensajeNovedades,
                resetearCargando
            }}>
            {props.children}
        </novedadesContext.Provider>
    )
}

export default NovedadesState;


