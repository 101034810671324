import { MENSAJE_ERROR, RESETEAR_CARGANDO, RESETEAR_MENSAJE, GET_BENEFICIOS_COMUNIDADES} from '../../types'

export default (state, action) => {
    switch (action.type) {
        case MENSAJE_ERROR:
            return {
                ...state,
                mensajeComunidades: action.payload,
                cargandoComunidades: false,
            }
        case RESETEAR_MENSAJE:
            return {
                ...state,
                mensajeComunidades: null,
            }
        case RESETEAR_CARGANDO:
            return {
                ...state,
                cargandoComunidades: true,
            }
        case GET_BENEFICIOS_COMUNIDADES:
            return {
                ...state,
                beneficiosComunidades: action.payload,
                cargandoComunidades:false
            }
        default:
            return state;
    }
}