import { useEffect, useContext, Fragment } from "react";
import beneficiosContext from "../../context/beneficios/beneficiosContext";
import $ from 'jquery';


const CheckboxesFiltros = (props) => {
    const { beneficiosMarcas, beneficiosDescuentos, beneficiosCategorias } = useContext(beneficiosContext);

    const filter_clicked = (type, index) => {
        var mostrar = false;
        props.list[index].checked = !props.list[index].checked;

        props.list.forEach((item, index) => {
            if (item.checked == true) mostrar = true;
        })

        switch (type) {
            case "marca":
                if (mostrar == true) {
                    props.setValoresFiltros({
                        ...props.valoresFiltros,
                        porMarca: mostrar,
                        verTodos: false
                    });
                }
                else {
                    props.setValoresFiltros({
                        ...props.valoresFiltros,
                        porMarca: mostrar
                    });
                }
                break;
            case "categoria":
                if (mostrar == true) {
                    props.setValoresFiltros({
                        ...props.valoresFiltros,
                        porCategoria: mostrar,
                        verTodos: false
                    });
                }
                else {
                    props.setValoresFiltros({
                        ...props.valoresFiltros,
                        porCategoria: mostrar,
                    });
                }
                break;
            case "descuento":
                if (mostrar == true) {
                    props.setValoresFiltros({
                        ...props.valoresFiltros,
                        porDescuento: mostrar,
                        verTodos: false
                    });
                }
                else {
                    props.setValoresFiltros({
                        ...props.valoresFiltros,
                        porDescuento: mostrar
                    });
                }
                break;
            case "moneda":
                if (mostrar == true) {
                    props.setValoresFiltros({
                        ...props.valoresFiltros,
                        porMoneda: mostrar,
                        verTodos: false
                    });
                }
                else {
                    props.setValoresFiltros({
                        ...props.valoresFiltros,
                        porMoneda: mostrar
                    });
                }
                break;
        }
        if (mostrar == false) {
            if (props.activarVerTodos(type)) {
                props.setValoresFiltros({
                    ...props.valoresFiltros,
                    verTodos: true,
                    porMarca: false,
                    porCategoria: false,
                    porDescuento: false,
                    porMoneda: false
                });
            }
        }
    }

    return (
        <div className="category-options">
            {props.list.map((item, index) => (
                <div className="" key={index} >
                    {item.nombreMarca &&
                        <Fragment>
                            <input
                                className='chk-filtros'
                                type="checkbox"
                                id={item.idMarca}
                                onClick={() => filter_clicked("marca", index)}
                                defaultChecked={item.checked}
                            />
                            <label className="fs-13-500" htmlFor={item.idMarca} >{item.nombreMarca}</label>
                        </Fragment>
                    }
                    {item.cantidadDescuento &&
                        <Fragment>
                            <input
                                className='chk-filtros'
                                type="checkbox"
                                id={item.idDescuento}
                                onClick={() => filter_clicked("descuento", index)}
                                defaultChecked={item.checked}
                            />
                            <label className="fs-13-500" htmlFor={item.idDescuento} >{item.cantidadDescuento}</label>
                        </Fragment>
                    }
                    {item.categoria &&
                        <Fragment>
                            <input
                                className='chk-filtros'
                                type="checkbox"
                                id={item.idCategoria}
                                onClick={() => filter_clicked("categoria", index)}
                                defaultChecked={item.checked}
                            />
                            <label className="fs-13-500" htmlFor={item.idCategoria}>{item.categoria}</label>
                        </Fragment>
                    }
                    {item.cantidadMonedas &&
                        <Fragment>
                            <input
                                className='chk-filtros'
                                type="checkbox"
                                id={`${item.cantidadMonedas}-Moneda`}
                                onClick={() => filter_clicked("moneda", index)}
                                defaultChecked={item.checked}
                            />
                            <label className="fs-13-500" htmlFor={`${item.cantidadMonedas}-Moneda`}> {item.cantidadMonedas === 10 && "+"} {item.cantidadMonedas} {item.cantidadMonedas < 2 ? "Moneda" : "Monedas"}</label>
                        </Fragment>
                    }
                </div>
            ))
            }
        </div>
    )
};

export default CheckboxesFiltros;
