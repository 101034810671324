import { CREAR_TICKET, CREAR_TICKET_ERROR, FAKE_CALLAPI, MENSAJE_ERROR, OBTENER_CATEGORIASFAQ, OBTENER_CATEGORIASFAQ_ERROR, OBTENER_NOVEDADES, OBTENER_NOVEDADES_ERROR, OBTENER_TEXTOS_AYUDA, RESETEAR_CARGANDO, RESETEAR_MENSAJE } from "../../types";


export default (state, action) => {
    switch (action.type) {
        case MENSAJE_ERROR:
        case OBTENER_CATEGORIASFAQ_ERROR:
        case OBTENER_NOVEDADES_ERROR:
        case CREAR_TICKET_ERROR:
            return {
                ...state,
                mensajeFaqs: action.payload,
                cargando: false,
            }
        case OBTENER_CATEGORIASFAQ:
            return {
                ...state,
                categoriasFaq: action.payload,
                cargando: false,
            }
        case OBTENER_NOVEDADES:
            return {
                ...state,
                novedades: action.payload
            }
        case CREAR_TICKET:
            return {
                ...state,
                cargando: false,
                mensajeFaqs: action.payload
            }
        case RESETEAR_MENSAJE:
            return {
                ...state,
                mensajeFaqs: null,
            }
        case RESETEAR_CARGANDO:
            return {
                ...state,
                cargando: true,
            }
        case FAKE_CALLAPI:
            return {
                ...state,
                cargando: false,
            }
        case OBTENER_TEXTOS_AYUDA:
            return {
                ...state,
                textosAyuda: action.payload,
                cargando: false,
            }
        default:
            return state;

    }
}