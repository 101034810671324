import React, { Fragment } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const Footer = () => {

    const location = useLocation().pathname;

    const handleNavOption = (option) => {
        window.dataLayer.push({
            "event": "click_footer",
            "category": option
        });
    }

    return (
        <Fragment>
            <a 
            className="ws-icon" 
                onClick={() => window.dataLayer.push({
                    "event": "click_whatsapp"
                })} 
            target="_blank"
             href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP}&text=Hola`} 
             rel="noreferrer">
                <img src={`${window.location.origin}/img/logo-whatsapp.png`} width="63px" height="63px" alt="Logo de whatsapp"/>
            </a>
            <div className='mt-auto footer' style={{ position: 'sticky', bottom: '0px', zIndex: '3' }}>
                <div className="mt-auto footer d-flex flex-row flex-wrap justify-content-between align-items-baseline" style={{ boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.05)" }}>
                    <NavLink to={"/home"} onClick={() => { handleNavOption("Inicio") }} className={({ isActive }) => isActive ? "d-flex flex-row align-self-stretch flex-fill align-items-center text-decoration-none active" : "d-flex flex-row align-self-stretch flex-fill align-items-center justify-content-center text-decoration-none bg-white"}>
                        {location.includes("/home") ?
                            <>
                                <div className="bg-white border-radius-left flex-fill align-self-stretch flex-grow-1 w-100"></div>
                                <div className="footer-active position-relative">
                                    {/* <div className="p-1 footer-active position-relative"> */}
                                    <svg width="42" height="43" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 20.5C0 9.45431 8.95431 0.5 20 0.5C31.0457 0.5 40 9.45431 40 20.5C40 31.5457 31.0457 40.5 20 40.5C8.95431 40.5 0 31.5457 0 20.5Z" fill="url(#paint0_linear_11186_20208)" />
                                        <path d="M11 17.5L20 10.5L29 17.5V28.5C29 29.0304 28.7893 29.5391 28.4142 29.9142C28.0391 30.2893 27.5304 30.5 27 30.5H13C12.4696 30.5 11.9609 30.2893 11.5858 29.9142C11.2107 29.5391 11 29.0304 11 28.5V17.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17 30.5V20.5H23V30.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <defs>
                                            <linearGradient id="paint0_linear_11186_20208" x1="-0.0575605" y1="0.499997" x2="45.853" y2="9.04203" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#EB008B" />
                                                <stop offset="1" stopColor="#CB0078" stopOpacity="0.8" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="bg-white border-radius-right flex-fill align-self-stretch w-100"></div>
                            </>
                            :
                            <svg width="42" height="43" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 17.5L20 10.5L29 17.5V28.5C29 29.0304 28.7893 29.5391 28.4142 29.9142C28.0391 30.2893 27.5304 30.5 27 30.5H13C12.4696 30.5 11.9609 30.2893 11.5858 29.9142C11.2107 29.5391 11 29.0304 11 28.5V17.5Z" stroke="#121860" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17 30.5V20.5H23V30.5" stroke="#121860" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        }
                    </NavLink>
                    <NavLink to={"/beneficios"} onClick={() => { handleNavOption("Beneficios") }} className={(navData) => navData.isActive ? "d-flex flex-row align-self-stretch flex-fill align-items-center text-decoration-none active" : "d-flex flex-row align-self-stretch flex-fill align-items-center justify-content-center text-decoration-none bg-white"}>
                        {location.includes("/beneficios") ?
                            <>
                                <div className="bg-white border-radius-left flex-fill align-self-stretch flex-grow-1 w-100"></div>
                                <div className="footer-active position-relative">
                                    <svg width="43" height="43" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.75 20.5C0.75 9.45431 9.70431 0.5 20.75 0.5C31.7957 0.5 40.75 9.45431 40.75 20.5C40.75 31.5457 31.7957 40.5 20.75 40.5C9.70431 40.5 0.75 31.5457 0.75 20.5Z" fill="url(#paint0_linear_11044_9205)" />
                                        <path d="M20.75 10.5L23.84 17.0825L30.75 18.1446L25.75 23.2655L26.93 30.5L20.75 27.0825L14.57 30.5L15.75 23.2655L10.75 18.1446L17.66 17.0825L20.75 10.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <defs>
                                            <linearGradient id="paint0_linear_11044_9205" x1="0.69244" y1="0.499997" x2="46.603" y2="9.04203" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#EB008B" />
                                                <stop offset="1" stopColor="#CB0078" stopOpacity="0.8" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="bg-white border-radius-right flex-fill align-self-stretch w-100"></div>
                            </>
                            :
                            <svg width="43" height="43" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.75 10.5L23.84 17.0825L30.75 18.1446L25.75 23.2655L26.93 30.5L20.75 27.0825L14.57 30.5L15.75 23.2655L10.75 18.1446L17.66 17.0825L20.75 10.5Z" stroke="#121860" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        }
                    </NavLink>
                    <NavLink to={"/cupones"} onClick={() => { handleNavOption("Cupones") }} className={(navData) => navData.isActive ? "d-flex flex-row align-self-stretch flex-fill align-items-center text-decoration-none active" : "d-flex flex-row align-self-stretch flex-fill align-items-center justify-content-center text-decoration-none bg-white"}>
                        {location.includes("/cupones") || location.includes('/compartir-cupon') ?
                            <>
                                <div className="bg-white border-radius-left flex-fill align-self-stretch flex-grow-1 w-100"></div>
                                <div className="footer-active position-relative">
                                    <svg width="43" height="43" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.5 20.5C0.5 9.45431 9.45431 0.5 20.5 0.5C31.5457 0.5 40.5 9.45431 40.5 20.5C40.5 31.5457 31.5457 40.5 20.5 40.5C9.45431 40.5 0.5 31.5457 0.5 20.5Z" fill="url(#paint0_linear_11044_9635)" />
                                        <path d="M20.5 23.2501C24.0439 23.2501 26.9167 20.3772 26.9167 16.8334C26.9167 13.2896 24.0439 10.4167 20.5 10.4167C16.9562 10.4167 14.0834 13.2896 14.0834 16.8334C14.0834 20.3772 16.9562 23.2501 20.5 23.2501Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17.0258 22.2326L15.9166 30.5834L20.5 27.8334L25.0833 30.5834L23.9741 22.2234" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <defs>
                                            <linearGradient id="paint0_linear_11044_9635" x1="0.44244" y1="0.499997" x2="46.353" y2="9.04203" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#EB008B" />
                                                <stop offset="1" stopColor="#CB0078" stopOpacity="0.8" />
                                            </linearGradient>
                                        </defs>
                                    </svg>


                                </div>
                                <div className="bg-white border-radius-right flex-fill align-self-stretch w-100"></div>
                            </>
                            :
                            <svg width="43" height="43" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.5002 23.2501C24.044 23.2501 26.9168 20.3772 26.9168 16.8334C26.9168 13.2896 24.044 10.4167 20.5002 10.4167C16.9563 10.4167 14.0835 13.2896 14.0835 16.8334C14.0835 20.3772 16.9563 23.2501 20.5002 23.2501Z" stroke="#121860" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.0262 22.2326L15.917 30.5834L20.5003 27.8334L25.0837 30.5834L23.9745 22.2234" stroke="#121860" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        }
                    </NavLink>
                    <NavLink to={"/estaciones"} onClick={() => { handleNavOption("Estaciones") }} className={(navData) => navData.isActive ? "d-flex flex-row align-self-stretch flex-fill align-items-center text-decoration-none active" : "d-flex flex-row align-self-stretch flex-fill align-items-center justify-content-center text-decoration-none bg-white"}>
                        {location.includes("/estaciones") ?
                            <>
                                <div className="bg-white border-radius-left flex-fill align-self-stretch flex-grow-1 w-100"></div>
                                <div className="footer-active position-relative">
                                    <svg width="43" height="43" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.25 20.5C0.25 9.45431 9.20431 0.5 20.25 0.5V0.5C31.2957 0.5 40.25 9.45431 40.25 20.5V20.5C40.25 31.5457 31.2957 40.5 20.25 40.5V40.5C9.20431 40.5 0.25 31.5457 0.25 20.5V20.5Z" fill="url(#paint0_linear_11044_9785)" />
                                        <path d="M28.5 18.6667C28.5 25.0834 20.25 30.5834 20.25 30.5834C20.25 30.5834 12 25.0834 12 18.6667C12 16.4787 12.8692 14.3803 14.4164 12.8331C15.9635 11.2859 18.062 10.4167 20.25 10.4167C22.438 10.4167 24.5365 11.2859 26.0836 12.8331C27.6308 14.3803 28.5 16.4787 28.5 18.6667Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M20.25 21.4167C21.7688 21.4167 23 20.1855 23 18.6667C23 17.148 21.7688 15.9167 20.25 15.9167C18.7312 15.9167 17.5 17.148 17.5 18.6667C17.5 20.1855 18.7312 21.4167 20.25 21.4167Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <defs>
                                            <linearGradient id="paint0_linear_11044_9785" x1="0.19244" y1="0.499997" x2="46.103" y2="9.04203" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#EB008B" />
                                                <stop offset="1" stopColor="#CB0078" stopOpacity="0.8" />
                                            </linearGradient>
                                        </defs>
                                    </svg>

                                </div>
                                <div className="bg-white border-radius-right flex-fill align-self-stretch w-100"></div>
                            </>
                            :
                            <svg width="43" height="43" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M28.5 18.6667C28.5 25.0834 20.25 30.5834 20.25 30.5834C20.25 30.5834 12 25.0834 12 18.6667C12 16.4787 12.8692 14.3803 14.4164 12.8331C15.9635 11.2859 18.062 10.4167 20.25 10.4167C22.438 10.4167 24.5365 11.2859 26.0836 12.8331C27.6308 14.3803 28.5 16.4787 28.5 18.6667Z" stroke="#0D1738" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M20.25 21.4167C21.7688 21.4167 23 20.1855 23 18.6667C23 17.148 21.7688 15.9167 20.25 15.9167C18.7312 15.9167 17.5 17.148 17.5 18.6667C17.5 20.1855 18.7312 21.4167 20.25 21.4167Z" stroke="#0D1738" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        }
                    </NavLink>
                    <NavLink to={"/mi-perfil"} onClick={() => { handleNavOption("Perfil") }} className={(navData) => navData.isActive ? "d-flex flex-row align-self-stretch flex-fill align-items-center text-decoration-none active" : "d-flex flex-row align-self-stretch flex-fill align-items-center justify-content-center text-decoration-none bg-white"}>
                        {location.includes("/mi-perfil") || location.includes("/crear-vehiculo") ?
                            <>
                                <div className="bg-white border-radius-left flex-fill align-self-stretch flex-grow-1 w-100"></div>
                                <div className="footer-active position-relative">
                                    <svg width="42" height="43" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 20.5C0 9.45431 8.95431 0.5 20 0.5C31.0457 0.5 40 9.45431 40 20.5C40 31.5457 31.0457 40.5 20 40.5C8.95431 40.5 0 31.5457 0 20.5Z" fill="url(#paint0_linear_11044_10018)" />
                                        <path d="M27.5 29.2207V27.2207C27.5 26.1598 27.0786 25.1424 26.3284 24.3923C25.5783 23.6421 24.5609 23.2207 23.5 23.2207H15.5C14.4391 23.2207 13.4217 23.6421 12.6716 24.3923C11.9214 25.1424 11.5 26.1598 11.5 27.2207V29.2207" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M19.5 19.2207C21.7091 19.2207 23.5 17.4298 23.5 15.2207C23.5 13.0116 21.7091 11.2207 19.5 11.2207C17.2909 11.2207 15.5 13.0116 15.5 15.2207C15.5 17.4298 17.2909 19.2207 19.5 19.2207Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <defs>
                                            <linearGradient id="paint0_linear_11044_10018" x1="-0.0575605" y1="0.499997" x2="45.853" y2="9.04203" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#EB008B" />
                                                <stop offset="1" stopColor="#CB0078" stopOpacity="0.8" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="bg-white border-radius-right flex-fill align-self-stretch w-100"></div>
                            </>
                            :
                            <svg width="42" height="43" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5 29.2207V27.2207C27.5 26.1598 27.0786 25.1424 26.3284 24.3923C25.5783 23.6421 24.5609 23.2207 23.5 23.2207H15.5C14.4391 23.2207 13.4217 23.6421 12.6716 24.3923C11.9214 25.1424 11.5 26.1598 11.5 27.2207V29.2207" stroke="#0D1738" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M19.5 19.2207C21.7091 19.2207 23.5 17.4298 23.5 15.2207C23.5 13.0116 21.7091 11.2207 19.5 11.2207C17.2909 11.2207 15.5 13.0116 15.5 15.2207C15.5 17.4298 17.2909 19.2207 19.5 19.2207Z" stroke="#0D1738" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        }
                    </NavLink>
                </div>
                <div className="bg-white pt-1"></div>
            </div>
        </Fragment>
    )
}

export default Footer;