import { CANJEAR_COMODIN, MENSAJE_ERROR, OBTENER_COMODINES, RESETEAR_CARGANDO, RESETEAR_MENSAJE, SET_NROS_SOCIO } from '../../types'

export default (state, action) => {
    switch (action.type) {
        case MENSAJE_ERROR:
            return {
                ...state,
                mensajeComodines: action.payload,
                cargandoComodines: false,
            }
        case OBTENER_COMODINES:
            return {
                ...state,
                comodines: action.payload.comodines,
                nrosSocio: action.payload.nrosSocio,
                cargandoComodines: false,
            }
        case SET_NROS_SOCIO:
            state.nrosSocio[action.payload.index] = action.payload.value
            return {
                ...state,
                nrosSocio: state.nrosSocio
            }
        case CANJEAR_COMODIN:
            return {
                ...state,
                mensajeComodines: action.payload
            }
        case RESETEAR_MENSAJE:
            return {
                ...state,
                mensajeComodines: null,
            }
        case RESETEAR_CARGANDO:
            return {
                ...state,
                cargandoComodines: true,
            }
        default:
            return state;

    }
}