import React, { useReducer } from "react";
import clienteAxios from "../../config/axios";
import {
    ACTUALIZAR_ESTADO_VEHICULO,
    CREAR_VEHICULO, CREAR_VEHICULO_ERROR, ELIMINAR_VEHICULO,
    ELIMINAR_VEHICULO_ERROR,
    OBTENER_MARCAS, OBTENER_MARCAS_ERROR,
    OBTENER_MODELOS, OBTENER_MODELOS_ERROR, OBTENER_TIPOS_VEHICULOS, OBTENER_TIPOS_VEHICULOS_ERROR, RESETEAR_CARGANDO, RESETEAR_MENSAJE,
} from "../../types";
import vehiculosContext from "./vehiculosContext";
import vehiculosReducer from "./vehiculosReducer";


const VehiculosState = props => {
    const initialState = {
        tiposVehiculos: null,
        marcas: [],
        modelos: [],
        vehiculos: null,
        mensaje: null,
        mensajeVehiculos: null,
        vehiculo: {
            tipo: '',
            marca: '',
            modelo: '',
            anio: ''
        },
        cargando: true,
    }

    const [state, dispatch] = useReducer(vehiculosReducer, initialState);

    const actualizarEstadoVehiculo = (datos) => {
        dispatch({
            type: ACTUALIZAR_ESTADO_VEHICULO,
            payload: datos,
        })
    }

    const obtenerTipoVehiculos = async () => {
        try {
            resetearCargando();

            const respuesta = await clienteAxios.get('gettipovehiculos')

            if (respuesta.headers["content-type"].indexOf('html') !== -1) {
                throw "";
            }

            dispatch({
                type: OBTENER_TIPOS_VEHICULOS,
                payload: respuesta.data,
            })


        } catch (error) {
            const alerta = {
                msg: 'Hay un error en servidor, intentá más tarde por favor.',
                categoria: 'error',
            }
            dispatch({
                type: OBTENER_TIPOS_VEHICULOS_ERROR,
                payload: alerta,
            })

        }
    }

    const obtenerMarcas = async (tipo) => {

        try {
            resetearCargando();

            const respuesta = await clienteAxios.get('getmarcasbytipo/' + tipo);
            if(respuesta.data?.statusCode==206){
                const alerta = {
                    msg: 'Hay un error en servidor, intentá más tarde por favor.',
                    categoria: 'error',
                }
                dispatch({
                    type: OBTENER_MARCAS_ERROR,
                    payload: alerta,
                })
            }
            else{
                const arrayMarcas = []

                if (respuesta.data?.response !== null) {

                    const aux = respuesta.data.response;
                    for (var i = 0; i < aux.length; i++) {
                        arrayMarcas.push({
                            id: i,
                            name: aux[i].marca,
                            modelos: []
                        })
                        for (var j = 0; j < aux[i].modelos?.length; j++) {
                            arrayMarcas[i].modelos.push({
                                id: j,
                                name: aux[i].modelos[j]
                            })
                        }
                    }
                }

                if (respuesta.headers["content-type"].indexOf('html') !== -1) {
                    throw "";
                }

                dispatch({
                    type: OBTENER_MARCAS,
                    payload: arrayMarcas,
                })
            }

        } catch (error) {
            const alerta = {
                msg: 'Hay un error en servidor, intentá más tarde por favor.',
                categoria: 'error',
            }
            dispatch({
                type: OBTENER_MARCAS_ERROR,
                payload: alerta,
            })

        }
    }

    const obtenerModelos = async (tipo) => {

        try {
            resetearCargando();

            const respuesta = await clienteAxios.get('getmodelosbytipo/' + tipo)

            if(respuesta.data?.statusCode==206){
                const alerta = {
                    msg: 'Hay un error en servidor, intentá más tarde por favor.',
                    categoria: 'error',
                }
                dispatch({
                    type: OBTENER_MODELOS_ERROR,
                    payload: alerta,
                })
            }
            else{
                const arrayModelos = []
    
                if (respuesta.data?.response !== null) {
                    const aux = respuesta.data?.response;
                    for (var i = 0; i < aux.length; i++) {
                        arrayModelos.push({
                            id: i,
                            name: aux[i]
                        })
                    }
                }
    
                if (respuesta.headers["content-type"].indexOf('html') !== -1) {
                    throw "";
                }
    
                dispatch({
                    type: OBTENER_MODELOS,
                    payload: arrayModelos,
                })
            }


        } catch (error) {
            const alerta = {
                msg: 'Hay un error en servidor, intentá más tarde por favor.',
                categoria: 'error',
            }
            dispatch({
                type: OBTENER_MODELOS_ERROR,
                payload: alerta,
            })
        }
    }

    const guardarVehiculo = async (datos) => {
        try {
            resetearCargando();
            const respuesta = await clienteAxios.post('createvehiculo', datos)

            if (respuesta.headers["content-type"].indexOf('html') !== -1) {
                throw "";
            }

            const alerta = {
                msg: 'Todos los datos de tu vehículo han sido cargados con éxito.',
                categoria: 'success',
            }

            dispatch({
                type: CREAR_VEHICULO,
                payload: alerta,
            })

        } catch (error) {
            const alerta = {
                msg: 'Hay un error en servidor, intentá más tarde por favor.',
                categoria: 'error',
            }
            dispatch({
                type: CREAR_VEHICULO_ERROR,
                payload: alerta,
            })
        }
    }

    const resetearCargando = () => {
        dispatch({
            type: RESETEAR_CARGANDO,
        })
    }

    const resetearMensajeVehiculos = () => {
        // setTimeout(() => {
        //     dispatch({
        //         type: RESETEAR_MENSAJE,
        //     })
        // }, 5000);

        dispatch({
            type: RESETEAR_MENSAJE,
        })
    }

    const eliminarVehiculo = async (id) => {
        try {
            await clienteAxios.delete('deletevehiculo/' + id);

            const alerta = {
                msg: 'El vehículo fué eliminado de manera exitosa.',
                categoria: 'success',
            }
            dispatch({
                type: ELIMINAR_VEHICULO,
                payload: alerta,
            })

            return true;

        } catch (error) {
            const alerta = {
                msg: 'Ha ocurrido un error al eliminar el vehículo.',
                categoria: 'error',
            }
            dispatch({
                type: ELIMINAR_VEHICULO_ERROR,
                payload: alerta,
            })

            return false;
        }
    }

    return (
        <vehiculosContext.Provider
            value={{
                tiposVehiculos: state.tiposVehiculos,
                marcas: state.marcas,
                modelos: state.modelos,
                vehiculos: state.vehiculos,
                mensaje: state.mensaje,
                mensajeVehiculos: state.mensajeVehiculos,
                vehiculo: state.vehiculo,
                cargando: state.cargando,
                actualizarEstadoVehiculo,
                obtenerTipoVehiculos,
                resetearMensajeVehiculos,
                resetearCargando,
                obtenerModelos,
                obtenerMarcas,
                guardarVehiculo,
                eliminarVehiculo
            }}>
            {props.children}
        </vehiculosContext.Provider>
    )
}

export default VehiculosState;


