import {
    OBTENER_VEHICULO,
    OBTENER_VEHICULO_ERROR,
    OBTENER_MARCAS,
    OBTENER_MARCAS_ERROR,
    OBTENER_MODELOS,
    OBTENER_MODELOS_ERROR, CREAR_VEHICULO_ERROR, CREAR_VEHICULO, ELIMINAR_VEHICULO, ACTUALIZAR_ESTADO_VEHICULO, RESETEAR_CARGANDO_MARCAS, RESETEAR_CARGANDO_MODELOS, OBTENER_TIPOS_VEHICULOS_ERROR, OBTENER_TIPOS_VEHICULOS, RESETEAR_CARGANDO, RESETEAR_MENSAJE, ELIMINAR_VEHICULO_ERROR
} from '../../types'

export default (state, action) => {
    switch (action.type) {
        case OBTENER_VEHICULO_ERROR:
        case OBTENER_MARCAS_ERROR:
        case ELIMINAR_VEHICULO:
        case ELIMINAR_VEHICULO_ERROR:
        case OBTENER_TIPOS_VEHICULOS_ERROR:
        case OBTENER_MODELOS_ERROR:
        case CREAR_VEHICULO_ERROR:
            return {
                ...state,
                mensajeVehiculos: action.payload,
                vehiculo: {
                    tipo: '',
                    marca: '',
                    modelo: '',
                    anio: '',
                },
                cargando: false,
            }
        case ACTUALIZAR_ESTADO_VEHICULO:
            return {
                ...state,
                vehiculo: action.payload
            }
        case OBTENER_TIPOS_VEHICULOS:
            return {
                ...state,
                tiposVehiculos: action.payload,
                cargando: false,
            }
        case OBTENER_MARCAS:
            return {
                ...state,
                marcas: action.payload,
                cargando: false,
            }
        case OBTENER_MODELOS:
            return {
                ...state,
                modelos: action.payload,
                cargando: false,
            }
        case CREAR_VEHICULO:
            return {
                ...state,
                mensajeVehiculos: action.payload,
                cargando: false,
            }
        case RESETEAR_CARGANDO:
            return {
                ...state,
                cargando: true,
            }
        case RESETEAR_MENSAJE:
            return {
                ...state,
                mensajeVehiculos: null,
            }
        default:
            return state;

    }
}