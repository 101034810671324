import React from 'react'

function Background(props) {
    return (
        <div className="d-flex flex-column min-vh-100 w-100"
            style={{
                background: `url(${window.location.origin}/img/gradiente.webp)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }
            }>
            
            {props.children}
        </div>
    )
}

export default Background
