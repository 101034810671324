import React, { useReducer } from "react";
import clienteAxios from "../../config/axios";
import { CREAR_TICKET, FAKE_CALLAPI, MENSAJE_ERROR, OBTENER_CATEGORIASFAQ, OBTENER_NOVEDADES, OBTENER_TEXTOS_AYUDA, RESETEAR_CARGANDO, RESETEAR_MENSAJE } from "../../types";
import faqsContext from "./faqsContext";
import faqsReducer from "./faqsReducer";


const FaqsState = props => {
    const initialState = {
        categoriasFaq: null,
        mensajeFaqs: null,
        novedades: null,
        cargando: true,
        textosAyuda: null
    }

    const [state, dispatch] = useReducer(faqsReducer, initialState);

    const obtenerFAQs = async () => {
        try {
            const respuesta = await clienteAxios.get('getfaqs')
            if(respuesta.data?.statusCode==206){
                enviarError(respuesta);  
            }
            else{
                dispatch({
                    type: OBTENER_CATEGORIASFAQ,
                    payload: respuesta.data.response,
                })
            }

        } catch (err) {
            enviarError(err);
        }
    }

    const obtenerNovedades = async () => {
        try {

            const respuesta = await clienteAxios.get('getnovedades')
            if(respuesta.data?.statusCode==206){
                enviarError(respuesta);  
            }
            else{
                dispatch({
                    type: OBTENER_NOVEDADES,
                    payload: respuesta.data.response,
                })
            }

        } catch (err) {
            enviarError(err);
        }
    }

    const enviarError = (err) => {
        var error=null;
        if(err.response!==undefined) error = err.response;
        if(err.data!==undefined) error = err;

        const alerta = {
            msg: error !== undefined ? error.data.message : "Este servicio no está disponible, por favor inténtelo de nuevo más tarde",
            categoria: error !== undefined ? error.data.type : "error",
        }
        dispatch({
            type: MENSAJE_ERROR,
            payload: alerta,
        })
    }

    const crearTicket = async (datos) => {
        try {

            resetearCargando();
            
            const respuesta = await clienteAxios.post('createticket', datos)
            
            if(respuesta.data?.statusCode==206){
                enviarError(respuesta);  
            }
            else{
                const alerta = {
                    msg: respuesta.data?.message,
                    categoria: respuesta.data?.type,
                }
                dispatch({
                    type: CREAR_TICKET,
                    payload: alerta,
                })
            }
        } catch (err) {
            enviarError(err);
        }
    }

    
    const obtenerTextosAyuda = async () => {
        try {

            resetearCargando();

            const respuesta = await clienteAxios.get('getayuda');

            dispatch({
                type: OBTENER_TEXTOS_AYUDA,
                payload: respuesta.data.response,
            })

        } catch (err) {
            enviarError(err);
        }
    }

    const resetearMensajeFaqs = () => {
        dispatch({
            type: RESETEAR_MENSAJE,
        })
    }

    const resetearCargando = () => {
        dispatch({
            type: RESETEAR_CARGANDO,
        })
    }

    const fakeCallApi = () => {
        dispatch({
            type: FAKE_CALLAPI,
        })
    }

    return (
        <faqsContext.Provider
            value={{
                categoriasFaq: state.categoriasFaq,
                novedades: state.novedades,
                mensajeFaqs: state.mensajeFaqs,
                cargando: state.cargando,
                textosAyuda: state.textosAyuda,
                obtenerNovedades,
                obtenerFAQs,
                obtenerTextosAyuda,
                crearTicket,
                resetearMensajeFaqs,
                fakeCallApi,
                resetearCargando
            }}>
            {props.children}
        </faqsContext.Provider>
    )
}

export default FaqsState;


