import { MENSAJE_ERROR, OBTENER_ONSTREAM, OBTENER_BENEFICIOS, RESETEAR_CARGANDO, OBTENER_ONSTREAM_CATEGORIA } from "../../types";

export default (state, action) => {
    switch (action.type) {
        case MENSAJE_ERROR:
        case OBTENER_ONSTREAM:
            return {
                ...state,
                onstream: action.payload,
                cargando: false,
            }
        case OBTENER_BENEFICIOS:
            return {
                ...state,
                beneficios: action.payload,
            }
        case OBTENER_ONSTREAM_CATEGORIA:
            return {
                ...state,
                categoria: action.payload,
                cargando: false,
            }
        case RESETEAR_CARGANDO:
            return {
                ...state,
                cargando: true,
            }
        default:
            return state;

    }
}