import {
    REGISTRO_EXITOSO,
    REGISTRO_ERROR,
    LOGIN_EXITOSO,
    CERRAR_SESION,
    LOGIN_ERROR,
    OBTENER_USUARIO,
    OBTENER_USUARIO_ERROR, CREAR_CUENTA, RESETEAR_CARGANDO, ACTUALIZAR_ESTADO_USUARIO, CREAR_CUENTA_ERROR, RESETEAR_GUARDANDO, RESETEAR_MENSAJE, ACTUALIZAR_USUARIO_ERROR, ACTUALIZAR_USUARIO, OBTENER_CARGAS, OBTENER_CARGAS_ERROR, OBTENER_NIVEL, OBTENER_NIVEL_ERROR, ACTUALIZAR_ESTADO_USUARIO_NUEVO_ERROR, OBTENER_NIVELES, OBTENER_NIVELES_ERROR, CANJEAR_CUPON_COMBUSTIBLE, CANJEAR_CUPON_COMBUSTIBLE_ERROR, MENSAJE_USUARIO_ERROR, OBTENER_HISTORIAL_CARGAS,PRIMERA_CARGA_REALIZADA, OBTENER_BENEFICIOS_NIVELES, SET_TUTORIAL_FALSE,GET_HOME
} from '../../types'

export default (state, action) => {
    switch (action.type) {
        case REGISTRO_EXITOSO:
        case REGISTRO_ERROR:
        case LOGIN_EXITOSO:
        case CERRAR_SESION:
        case LOGIN_ERROR:
        case OBTENER_USUARIO_ERROR:
        case OBTENER_CARGAS_ERROR:
        case ACTUALIZAR_USUARIO_ERROR:
        case CREAR_CUENTA_ERROR:
        case OBTENER_NIVEL_ERROR:
        case ACTUALIZAR_ESTADO_USUARIO_NUEVO_ERROR:
        case OBTENER_NIVELES_ERROR:
        case CANJEAR_CUPON_COMBUSTIBLE_ERROR:
        case MENSAJE_USUARIO_ERROR:
            return {
                ...state,
                mensaje: action.payload,
                cargando: false,
                guardando: false,
            }
        case CREAR_CUENTA:
            return {
                ...state,
                usuario: action.payload,
                guardando: false,
                redirect: true,
            }
        case ACTUALIZAR_USUARIO:
            return {
                ...state,
                mensaje: action.payload,
                cargando: false,
            }
        case OBTENER_USUARIO:
            return {
                ...state,
                usuario: action.payload,
                cargando: false,
            }
        case RESETEAR_CARGANDO:
            return {
                ...state,
                cargando: true,
            }
        case RESETEAR_GUARDANDO:
            return {
                ...state,
                guardando: true,
            }
        case RESETEAR_MENSAJE:
            return {
                ...state,
                mensaje: null,
                cuponCombustible: null,
            }
        case ACTUALIZAR_ESTADO_USUARIO:
            return {
                ...state,
                usuario: action.payload
            }
        case OBTENER_CARGAS:
            return {
                ...state,
                cargasCombustible: action.payload,
                cargando: false,
            }
        case OBTENER_NIVEL:
            return {
                ...state,
                contacto: action.payload,
                cargando: false,
            }
        case OBTENER_NIVELES:
            return {
                ...state,
                niveles: action.payload,
                cargando: false,
            }
        case OBTENER_BENEFICIOS_NIVELES:
            return {
                ...state,
                niveles: state.niveles.map((nivel) => {
                    if (nivel.nombre == action.payload.nivel) {
                        nivel = {...nivel, beneficios: action.payload.beneficios}
                    }
                    return nivel;
                }),
                cargando: false,
            }
        case CANJEAR_CUPON_COMBUSTIBLE:
            return {
                ...state,
                cuponCombustible: action.payload,
                cargando: false,
            }
        case OBTENER_HISTORIAL_CARGAS:
            return {
                ...state,
                historialCargas: action.payload,
                cargando: false,
            }
        case PRIMERA_CARGA_REALIZADA:
            return{
                ...state,
                primeraCargaRealizadaMostrar:action.payload,
                cargando:false
            }
        case SET_TUTORIAL_FALSE:
            return {
                ...state,
                contacto: {
                    ...state.contacto,
                    verTutorial: false
                }
            }
        case GET_HOME:
                return{
                    ...state,
                    novedades: action.payload.listaNovedadesHome,
                    cargasCombustible: action.payload.cargasCombustible,
                    beneficiosExclusivos: action.payload.listaBeneficiosExclusivos,
                    cargando:false
                }
        default:
            return state;

    }
}