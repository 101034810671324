import { MENSAJE_ERROR, RESETEAR_CARGANDO, RESETEAR_MENSAJE, OBTENER_LEGALES, OBTENER_POLITICA } from "../../types";

export default (state, action) => {
    switch (action.type) {
        case MENSAJE_ERROR:
            return {
                ...state,
                mensajeLegales: action.payload,
                cargando: false,
            }
        case OBTENER_LEGALES:
            return {
                ...state,
                legales: action.payload,
                cargando: false
            }
        case OBTENER_POLITICA:
            return {
                ...state,
                politica: action.payload,
                cargando: false
            }
        case RESETEAR_MENSAJE:
            return {
                ...state,
                mensajeLegales: null,
            }
        case RESETEAR_CARGANDO:
            return {
                ...state,
                cargando: true,
            }
        default:
            return state;

    }
}