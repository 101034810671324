import react, { Fragment } from "react";
import Background from '../layout/Background';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Onboarding from '../layout/Onboarding';
import { Link, useNavigate } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

const ComodinesOnBording = () => {

    const navigate = useNavigate();

    return (
        <Fragment>
            <AuthenticatedTemplate>
                <Background >
                    <div className="d-flex flex-column bg-white-rounded justify-content-center flex-fill align-self-center">
                        <div className="my-auto mx-auto">
                            <img
                                src={`${window.location.origin}/img/logo-axion.svg`}
                                className="d-block w-100"
                                alt="Logo de AXION Energy"
                                width="90px"
                                height="89px" />
                        </div>
                        <div className="my-auto px-2">
                            <Carousel className='text-center'>
                                <Carousel.Item className='mb-5' interval={8000}>
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                        <img src={`${window.location.origin}/img/onboarding-comodin/comodin-descuento.svg`} className="" width="211px" height="162px" />
                                        <div className="mt-2 text-center">
                                            <p className="fs-18 pt-5">Los comodines te permiten sumar descuentos extras en el nivel que estes.</p>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item className='mb-5' interval={8000}>
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                        <img src={`${window.location.origin}/img/onboarding-comodin/comodin-tickets.svg`} className="" width="211px" height="162px" />
                                        <div className="mt-2 text-center">
                                            <p className="fs-18 pt-5">¿Estás asociado a un programa amigo? Completa tus datos y accedé a comodines.</p>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className="mt-auto d-grid gap-2">
                            <Link to={('/comodines')} className="btn btn-azul fs-16-600 w-100">Ver programas amigos</Link>
                            <Link to={('/home')} className="btn btn-none fs-16-600 text-fucsia py-3">Salir</Link>
                        </div>
                    </div>
                </Background>
            </AuthenticatedTemplate >
            <UnauthenticatedTemplate>
                <Onboarding />
            </UnauthenticatedTemplate>
        </Fragment >
    )


}
export default ComodinesOnBording;