import { useEffect, useRef } from 'react';
import { shareFacebook } from '../../utils/facebook';
import { shareTwitter } from '../../utils/twitter';
import { email } from '../../utils/email';
import { linkedin } from '../../utils/linkedin';
import { pinterest } from '../../utils/pinterest';
import { reddit } from '../../utils/reddit';
import { whatsapp } from '../../utils/whatsapp';
import { copy } from '../../utils/copy';
import { hackernews } from '../../utils/hackernews';
import { telegram } from '../../utils/telegram';
import { Fragment } from 'react';

const WebSocialShare = ({ redesSociales, share, setShareSocialMedia }) => {
  const refContainerCompartir = useRef();

  useEffect(()=>{
    for(var i=0;i<redesSociales.config.length;i++){
      if (redesSociales.config[i].socialShareUrl === '') {
        if (redesSociales.config[i].redSocial === "Email") {
          redesSociales.config[i].socialShareBody = "Te comparto este excelente concurso. " + "\n" + window.location.href;
        }
        else {
          if (redesSociales.config[i].redSocial === "Copy" || redesSociales.config[i].redSocial === "Facebook") {
            if (redesSociales.config[i].socialShareUrl != undefined) redesSociales.config[i].socialShareUrl = window.location.href;
          }
          else {
            if (redesSociales.config[i].socialShareUrl != undefined) redesSociales.config[i].socialShareUrl = "Te comparto este excelente concurso. " + "\n" + window.location.href;
          }
        }
      }
    }
  },[])

  useEffect(()=>{
    var clickFuera=(e)=>{
      if(refContainerCompartir && !refContainerCompartir.current.contains(e.target)){
        setShareSocialMedia(false);

        var sidebar = document.querySelector(".social-media-container");

        if (sidebar.classList.contains("open"))
          sidebar.classList.remove("open");
      }
    }
    document.addEventListener("mousedown",clickFuera);
    return ()=>{
      document.removeEventListener("mousedown",clickFuera);
    }
  })


  const handleShare =async(e,socialMedia)=>{

      switch(socialMedia.redSocial){
        case "Facebook": 
          await shareFacebook(socialMedia);
          break;  
        case "Twitter": 
          await shareTwitter(socialMedia);
          break;  
        case "Email": 
          email(socialMedia);
          break;  
        case "linkedin": 
          linkedin(socialMedia);
          break;  
        case "pinterest": 
          pinterest(socialMedia);
          break;  
        case "reddit": 
          reddit(socialMedia);
          break;  
        case "Whats App": 
          whatsapp(socialMedia);
          break;  
        case "Copy": 
          copy(socialMedia);
          break;  
        case "hackernews": 
          hackernews(socialMedia);
          break;  
        case "Telegram": 
          telegram(socialMedia);
          break;
        default: break;
    }

    e.stopPropagation();
    setTimeout(() => setShareSocialMedia(false), 250);
  }

  return (
      <div className={`social-media-container p-3 ${share ? 'open' : ''}`} style={{zIndex:'99999999'}}>
        <div className="social-media" ref={refContainerCompartir}>
          { redesSociales.config.map((redSocial,index)=>(
              <Fragment key={index}>
                  <button className="social-media-item btn btn-none" onClick={(e) => handleShare(e,redSocial)}>
                <img
                  src={`${window.location.origin}${redSocial.icon}`}
                  width="35px"
                  height="35px"
                  alt={redSocial.redSocial}
                />
                      <p className='text-dark fs-10 pt-2'>{redSocial.redSocial}</p>
                  </button>
              </Fragment>
            ))
          }
        </div>
      </div>
  )
}

export default WebSocialShare;