import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import clienteAxios from '../../config/axios';
import Swal from 'sweetalert2';
import { Fragment } from 'react/cjs/react.production.min';
import Cargando from '../layout/Cargando';
import WebSocialShare from '../social-share/webSocialShare';


function CompartirCupon() {

    const [step, setStep] = useState(1);
    const [dni, setDni] = useState('');
    const [validated, setValidated] = useState(false);
    const [inputError, setInputError] = useState('');
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const [shareSocialMedia, setShareSocialMedia] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const path = location.state.path;
    const data = location.state.cupon;

    const socialMedia = {
        displayNames: true,
        config: [
            {
                redSocial: "Facebook",
                socialShareUrl: window.location.origin,
                icon: "/img/iconos-redes-sociales/facebook.svg",
            },
            {
                redSocial: "Copy",
                socialShareUrl: window.location.origin,
                icon: "/img/iconos-redes-sociales/copy.svg"
            },
            {
                redSocial: "Email",
                socialShareBody: "Te invito a registrarte en ON, para utilizar el beneficio que te comparto. \n" + window.location.origin,
                socialShareSubject: "Invitación a la App Axion Energy",
                icon: "/img/iconos-redes-sociales/email.svg"
            },
            {
                redSocial: "Twitter",
                socialShareUrl: "Te invito a registrarte en ON, para utilizar el beneficio que te comparto. \n" + window.location.origin,
                icon: "/img/iconos-redes-sociales/twitter.svg"
            },
            {
                redSocial: "Whats App",
                socialShareUrl: "Te invito a registrarte en ON, para utilizar el beneficio que te comparto. \n" + window.location.origin,
                icon: "/img/iconos-redes-sociales/whatsapp.svg"
            },
            {
                redSocial: "Telegram",
                socialShareUrl: "Te invito a registrarte en ON, para utilizar el beneficio que te comparto. \n" + window.location.origin,
                icon: "/img/iconos-redes-sociales/telegram.svg"
            }
        ]
    }

    useEffect(() => {
        if (data.cuponId === null ||
            data.codigoCupon === null ||
            data.tipoCupon === null ||
            data.cuponId === undefined ||
            data.codigoCupon === undefined ||
            data.tipoCupon === undefined
        ) navigate(-1);
    }, [data, navigate])

    const handleValidarContact = async () => {

        if (dni === '') {
            setInputError("*Este campo es obligatorio");
            return;
        }
        if (inputError) return;
        setLoading(true);

        await clienteAxios.get('validatecontact/' + dni).then((res) => {

            if (res.data.type !== 'success') {
                setValidated(false);
            } else {
                const response = res.data.response;

                setFormData({
                    cuponId: data.cuponId,
                    codigoCupon: data.codigoCupon,
                    tipoCupon: data.tipoCupon,
                    dniDestinoId: response[1].id,
                    dniDestino: response[1].dni,
                    dniOrigenId: response[0].id,
                    nombre: response[1].nombre,
                    apellido: response[1].apellido
                })

                setValidated(true);
            }

            handleNextStep();

        }).catch((err) => {
            handleError(err)
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleError = (err) => {
        var error = null;
        if (err.response !== undefined) error = err.response;
        if (err.data !== undefined) error = err;

        const errMsg = error !== undefined ? error.data.message : "Este servicio no está disponible, por favor inténtelo de nuevo más tarde"
        Swal.fire({
            html: `
                    <p class="fs-24-bold mt-4">¡Ups! Algo salió mal</p>
                    <img class="my-4" src="${window.location.origin}/img/popups/error.svg" />
                    <p class="fs-14-400">${errMsg}</p>
                    `,
            confirmButtonText: 'Aceptar',
            showCloseButton: true,
            closeButtonHtml: `
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.2095 1.70711C18.6001 1.31658 18.6001 0.683417 18.2095 0.292893C17.819 -0.0976311 17.1859 -0.0976311 16.7953 0.292893L9.50122 7.587L2.20711 0.292893C1.81658 -0.0976311 1.18342 -0.0976311 0.792893 0.292893C0.402369 0.683417 0.402369 1.31658 0.792893 1.70711L8.087 9.00122L0.792893 16.2953C0.402369 16.6859 0.402369 17.319 0.792893 17.7095C1.18342 18.1001 1.81658 18.1001 2.20711 17.7095L9.50122 10.4154L16.7953 17.7095C17.1859 18.1001 17.819 18.1001 18.2095 17.7095C18.6001 17.319 18.6001 16.6859 18.2095 16.2953L10.9154 9.00122L18.2095 1.70711Z" fill="#8F8E8E" />
                    </svg>
                    `
        })
    }

    const handleChangePromoCoupon = async () => {

        setLoading(true);

        await clienteAxios.post('changecuponcombustible', formData).then((res) => {
            if (res.data.statusCode !== 200) {
                throw res;
            }

            setValidated(true);
            handleNextStep();

        }).catch((err) => {
            handleError(err);
            handleBackStep();
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleInputChange = (e) => {

        if (/\D/.test(e.target.value)) setInputError("*Ingresá tu DNI sólo con números")
        else if (e.target.value.length < 1) setInputError("*Este campo es obligatorio")
        else setInputError('')

        setDni(e.target.value);
    };

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handleBackStep = () => {
        if (step === 1) {
            navigate(path?.includes('cupones') ? '/cupones' : '/home');
        }
        setStep(step - 1);;
    };

    if (loading) return <Cargando />

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <div className='d-flex flex-column gap-3 text-center'>
                        <p className='fs-18-bold'>¿A quién deseas regalarle el Descuento?</p>
                        <p className='fs-14-400'>Una vez enviado el cupón, ya no lo tendrás disponible, pero tu amigo estará feliz de recibirlo. <br />Dejanos sus datos para mandárselo.</p>
                        <div className="text-start">
                            <label htmlFor="dni" className="form-label fs-14-500">DNI de tu amigo</label>
                            <input
                                type="text"
                                className={`form-control ${inputError ? 'is-invalid' : ''}`}
                                id="dni"
                                placeholder="Ingresá el DNI sin puntos"
                                value={dni}
                                required
                                maxLength="8"
                                onChange={handleInputChange} />
                            <div className="invalid-feedback campo-invalido">
                                {inputError}
                            </div>
                        </div>
                        <button type='button' className="btn btn-azul fs-16-bold" onClick={() => { handleValidarContact() }}>Siguiente</button>
                    </div>
                );
            case 2:
                return (
                    <div className='d-flex flex-column gap-3 text-center'>
                        {validated ?
                            <Fragment>
                                <img
                                    className='mx-auto mb-3'
                                    src={`${window.location.origin}/img/compartir-cupon/cupon-a-compartir.svg`}
                                    style={{ width: '180px', height: '100%' }}
                                    alt='Nave espacial'
                                />
                                <p className='text-rosa fs-24-bold'>{formData.nombre} es parte de ON</p>
                                <p className='text-azul fs-16-400'>El DNI que ingresaste corresponde
                                    a {formData.nombre + ' ' + formData.apellido}. <b>¿Deseas compartirle
                                        el Descuento?</b> </p>
                                <button type='button' className="btn btn-azul fs-16-bold" onClick={() => { handleChangePromoCoupon() }}>Enviar Descuento</button>
                            </Fragment>
                            :
                            <Fragment>
                                <img
                                    className='mx-auto mb-3'
                                    src={`${window.location.origin}/img/compartir-cupon/cupon-no-compartido.svg`}
                                    style={{ width: '180px', height: '100%' }}
                                    alt='Cupon no compartido'
                                />
                                <p className='text-rosa fs-24-bold'>Invitá a tu amigo a formar parte de ON</p>
                                <p className='text-azul fs-16-400'>El DNI ingresado no corresponde a un
                                    usuario registrado.  <b>Invitalo a qué se
                                        registre para disfrutar de este Beneficio.</b> </p>
                                <button type='button' className="btn btn-azul fs-16-bold" onClick={() => setShareSocialMedia(true)}>Enviar Invitación</button>
                                <div className='w-auto'>
                                    <Link to={path?.includes('cupones') ? '/cupones' : '/home'} className='btn-none text-decoration-none text-rosa fs-16-bold'>Salir</Link>
                                </div>
                            </Fragment>
                        }
                    </div>
                );
            case 3:
                return (
                    <div className='d-flex flex-column gap-3 text-center'>
                        <img
                            className='mx-auto mb-3'
                            src={`${window.location.origin}/img/compartir-cupon/cupon-compartido.svg`}
                            style={{ width: '180px', height: '100%' }}
                            alt='Cupon no compartido'
                        />
                        <p className='text-rosa fs-24-bold'>¡Descuento Compartido!</p>
                        <p className='text-azul fs-16-400'>{formData.nombre} ya puede disfrutar del descuento acercándose con su <b>DNI a una estación Axion energy.</b> ¡Lo esperamos!</p>
                        <Link to={path?.includes('cupones') ? '/cupones' : '/home'} type='button' className="btn btn-azul fs-16-bold">Finalizar</Link>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className='d-flex flex-column min-vh-100 w-100'>
            <Header />
            <WebSocialShare redesSociales={socialMedia} share={shareSocialMedia} setShareSocialMedia={setShareSocialMedia} />
            <div className='p-3 d-flex flex-column gap-3'>
                {validated &&
                    <div className="progress">
                        <div className={`progress-bar mx-1 ${step > 0 && 'active'}`} role="progressbar" style={{ width: "35%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" />
                        <div className={`progress-bar mx-1 ${step > 1 && 'active'}`} role="progressbar" style={{ width: "35%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" />
                        <div className={`progress-bar mx-1 ${step > 2 && 'active'}`} role="progressbar" style={{ width: "35%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" />
                    </div>
                }
                <div className="d-flex flex-row justify-content-between align-items-center gap-2 text-gris">
                    {step === 3 ?
                        <p className="fs-16-400 text-azul">Compartir cupón</p>
                        :
                        <button onClick={handleBackStep} className="btn-none d-flex flex-row align-items-center text-decoration-none gap-2">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289C12.0976 3.68342 12.0976 4.31658 11.7071 4.70711L5.41421 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H5.41421L11.7071 19.2929C12.0976 19.6834 12.0976 20.3166 11.7071 20.7071C11.3166 21.0976 10.6834 21.0976 10.2929 20.7071L2.29289 12.7071C1.90237 12.3166 1.90237 11.6834 2.29289 11.2929L10.2929 3.29289Z" fill="#0C1561" />
                            </svg>
                            <span className="fs-16-400 text-azul">Compartir cupón</span>
                        </button>
                    }
                    {validated &&
                        <p className="fs-16-400">{step}/3</p>
                    }
                </div>

                {renderStep()}

            </div>
            <Footer />
        </div>
    )
}

export default CompartirCupon