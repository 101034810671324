export const REGISTRO_EXITOSO = 'REGISTRO_EXITOSO';
export const REGISTRO_ERROR = 'REGISTRO_ERROR';
export const OBTENER_USUARIO = 'OBTENER_USUARIO';
export const OBTENER_USUARIO_ERROR = 'OBTENER_USUARIO_ERROR';
export const ACTUALIZAR_USUARIO = 'ACTUALIZAR_USUARIO';
export const ACTUALIZAR_USUARIO_ERROR = 'ACTUALIZAR_USUARIO_ERROR';
export const LOGIN_EXITOSO = 'LOGIN_EXITOSO';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const CERRAR_SESION = 'CERRAR_SESION';
export const CREAR_CUENTA = 'CREAR_CUENTA';
export const CREAR_CUENTA_ERROR = 'CREAR_CUENTA_ERROR';
export const OBTENER_CARGAS = 'OBTENER_CARGAS';
export const OBTENER_CARGAS_ERROR = 'OBTENER_CARGAS_ERROR';
export const OBTENER_NIVEL = 'OBTENER_NIVEL';
export const OBTENER_NIVEL_ERROR = 'OBTENER_NIVEL_ERROR';
export const OBTENER_NIVELES = 'OBTENER_NIVELES'
export const OBTENER_NIVELES_ERROR = 'OBTENER_NIVELES_ERROR';
export const OBTENER_ONSTREAM = 'OBTENER_ONSTREAM';
export const OBTENER_VEHICULO = 'OBTENER_VEHICULO';
export const OBTENER_VEHICULO_ERROR = 'OBTENER_VEHICULO_ERROR';
export const OBTENER_TIPOS_VEHICULOS = 'OBTENER_TIPOS_VEHICULOS';
export const OBTENER_TIPOS_VEHICULOS_ERROR = 'OBTENER_TIPOS_VEHICULOS_ERROR';
export const OBTENER_MARCAS = 'OBTENER_MARCA';
export const OBTENER_MARCAS_ERROR = 'OBTENER_MARCA_ERROR';
export const OBTENER_MODELOS = 'OBTENER_MODELO';
export const OBTENER_MODELOS_ERROR = 'OBTENER_MODELO_ERROR';

export const CREAR_VEHICULO = 'CREAR_VEHICULO';
export const CREAR_VEHICULO_ERROR = 'CREAR_VEHICULO_ERROR';
export const ELIMINAR_VEHICULO = 'ELIMINAR_VEHICULO';
export const ELIMINAR_VEHICULO_ERROR = 'ELIMINAR_VEHICULO_ERROR';

export const CREAR_TICKET = 'CREAR_TICKET';
export const CREAR_TICKET_ERROR = 'CREAR_TICKET_ERROR';

export const RESETEAR_GUARDANDO = 'RESETEAR_GUARDANDO';
export const RESETEAR_CARGANDO = 'RESETEAR_CARGANDO';
export const RESETEAR_CARGANDO_MARCAS = 'RESETEAR_CARGANDO_MARCAS'
export const RESETEAR_CARGANDO_MODELOS = 'RESETEAR_CARGANDO_MODELOS'

export const ACTUALIZAR_ESTADO_USUARIO = 'ACTUALIZAR_ESTADO_USUARIO';
export const ACTUALIZAR_ESTADO_VEHICULO = 'ACTUALIZAR_ESTADO_VEHICULO';
export const ACTUALIZAR_ESTADO_USUARIO_NUEVO = 'ACTUALIZAR_ESTADO_USUARIO_NUEVO';
export const ACTUALIZAR_ESTADO_USUARIO_NUEVO_ERROR = 'ACTUALIZAR_ESTADO_USUARIO_NUEVO_ERROR';

export const ELIMINAR_PROGRAMA = 'ELIMINAR_PROGRAMA';

export const CREAR_CUPON = 'CREAR_CUPON'
export const CREAR_CUPON_ERROR = 'CREAR_CUPON_ERROR'
export const CANJEAR_CUPON_COMBUSTIBLE = 'CANJEAR_CUPON_COMBUSTIBLE'
export const CANJEAR_CUPON_COMBUSTIBLE_ERROR = 'CANJEAR_CUPON_COMBUSTIBLE_ERROR'
export const OBTENER_CUPONES = 'OBTENER_CUPONES'
export const OBTENER_CUPONES_ERROR = 'OBTENER_CUPONES_ERROR'
export const OBTENER_CUPONES_CANJEADOS = 'OBTENER_CUPONES_CANJEADOS'
export const OBTENER_CUPONES_CANJEADOS_ERROR = 'OBTENER_CUPONES_CANJEADOS_ERROR'

export const FAKE_CALLAPI = 'FAKE_CALLAPI'

export const OBTENER_BENEFICIO = 'OBTENER_BENEFICIO'
export const OBTENER_BENEFICIO_ERROR = 'OBTENER_BENEFICIO_ERROR'
export const OBTENER_BENEFICIOS = 'OBTENER_BENEFICIOS'
export const OBTENER_BENEFICIOS_ERROR = 'OBTENER_BENEFICIOS_ERROR'
export const OBTENER_BENEFICIOS_CATEGORIA = 'OBTENER_BENEFICIOS_CATEGORIA'
export const OBTENER_BENEFICIOS_CATEGORIA_ERROR = 'OBTENER_BENEFICIOS_CATEGORIA_ERROR'
export const OBTENER_BENEFICIOS_FAVORITOS = 'OBTENER_BENEFICIOS_FAVORITOS'
export const OBTENER_BENEFICIOS_FAVORITOS_ERROR = 'OBTENER_BENEFICIOS_FAVORITOS_ERROR'
export const OBTENER_BENEFICIOS_EXCLUSIVOS = 'OBTENER_BENEFICIOS_EXCLUSIVOS'
export const OBTENER_BENEFICIOS_EXCLUSIVOS_ERROR = 'OBTENER_BENEFICIOS_EXCLUSIVOS_ERROR'
export const OBTENER_BENEFICIOS_EXCLUSIVOS_GEO = 'OBTENER_BENEFICIOS_EXCLUSIVOS_GEO'
export const OBTENER_BENEFICIOS_EXCLUSIVOS_GEO_ERROR = 'OBTENER_BENEFICIOS_EXCLUSIVOS_GEO_ERROR'
export const OBTENER_BENEFICIOS_PROVINCIA = 'OBTENER_BENEFICIOS_PROVINCIA'
export const OBTENER_BENEFICIOS_PROVINCIA_GEO = 'OBTENER_BENEFICIOS_PROVINCIA_GEO'
export const OBTENER_ONSTREAM_CATEGORIA = 'OBTENER_ONSTREAM_CATEGORIA'
export const OBTENER_CATEGORIASFAQ = 'OBTENER_CATEGORIASFAQ'
export const OBTENER_CATEGORIASFAQ_ERROR = 'OBTENER_CATEGORIASFAQ_ERROR'
export const OBTENER_NOVEDADES = 'OBTENER_NOVEDADES'
export const OBTENER_NOVEDADES_ERROR = 'OBTENER_NOVEDADES_ERROR'
export const OBTENER_NOVEDAD = 'OBTENER_NOVEDAD'

export const RESETEAR_MENSAJE = 'RESETEAR_MENSAJE'

export const MENSAJE_ERROR = 'MENSAJE_ERROR'
export const MENSAJE_USUARIO_ERROR = 'MENSAJE_USUARIO_ERROR'

export const OBTENER_ALIANZAS = 'OBTENER_ALIANZAS'
export const OBTENER_ALIANZAS_ERROR = 'OBTENER_ALIANZAS_ERROR'
export const OBTENER_PROGRAMAS = 'OBTENER_PROGRAMAS'
export const OBTENER_PROGRAMAS_ERROR = 'OBTENER_PROGRAMAS_ERROR'
export const ELIMINAR_ALIANZA = 'ELIMINAR_ALIANZA'
export const ELIMINAR_ALIANZA_ERROR = 'ELIMINAR_ALIANZA_ERROR'
export const CREAR_ALIANZA = 'CREAR_ALIANZA';
export const CREAR_ALIANZA_ERROR = 'CREAR_ALIANZA_ERROR';

export const RESETEAR_CREADO = 'RESETEAR_CREADO';

export const OBTENER_HISTORIAL_CARGAS = 'OBTENER_HISTORIAL_CARGAS'
export const OBTENER_HISTORIAL_CARGAS_ERROR = 'OBTENER_HISTORIAL_CARGAS_ERROR'

export const AGREGAR_BENEFICIO_A_FAVORITOS = 'AGREGAR_BENEFICIO_A_FAVORITOS'
export const AGREGAR_BENEFICIO_A_FAVORITOS_ERROR = 'AGREGAR_BENEFICIO_A_FAVORITOS_ERROR'
export const ELIMINAR_BENEFICIO_A_FAVORITOS = 'ELIMINAR_BENEFICIO_A_FAVORITOS'
export const ELIMINAR_BENEFICIO_A_FAVORITOS_ERROR = 'ELIMINAR_BENEFICIO_A_FAVORITOS_ERROR'
export const AGREGAR_SUCURSAL_A_FAVORITOS = 'AGREGAR_SUCURSAL_A_FAVORITOS'
export const AGREGAR_SUCURSAL_A_FAVORITOS_ERROR = 'AGREGAR_SUCURSAL_A_FAVORITOS_ERROR'
export const ELIMINAR_SUCURSAL_A_FAVORITOS = 'ELIMINAR_SUCURSAL_A_FAVORITOS'
export const ELIMINAR_SUCURSAL_A_FAVORITOS_ERROR = 'ELIMINAR_SUCURSAL_A_FAVORITOS_ERROR'

export const OBTENER_COMODINES = 'OBTENER_COMODINES'
export const OBTENER_COMODINES_ERROR = 'OBTENER_COMODINES_ERROR'
export const CANJEAR_COMODIN = 'CANJEAR_COMODIN'
export const CANJEAR_COMODIN_ERROR = 'CANJEAR_COMODIN_ERROR'

export const SET_NROS_SOCIO = 'SET_NROS_SOCIO'

export const CONCURSOS_BANNER_ITEM = 'CONCURSOS_BANNER_ITEM'
export const OBTENER_CONCURSO = 'OBTENER_CONCURSO'
export const MODAL_CONCURSOS_RESPUESTAS = 'MODAL_CONCURSOS_RESPUESTAS'
export const PINTAR_REPUESTA_CONCURSO = 'PINTAR_REPUESTA_CONCURSO'
export const OBTENER_CONCURSO_PREGUNTAS = 'OBTENER_CONCURSO_PREGUNTAS'
export const CONCURSOS_GANADORES = 'CONCURSOS_GANADORES'
export const OBTENER_FOTOS_PARA_VOTAR = 'OBTENER_FOTOS_PARA_VOTAR'

export const OBTENER_LEGALES = 'OBTENER_LEGALES'
export const OBTENER_POLITICA = 'OBTENER_POLITICA'

export const BENEFICIOS_BUSCADOS = 'BENEFICIOS_BUSCADOS'
export const PRIMERA_CARGA_REALIZADA = 'PRIMERA_CARGA_REALIZADA'

export const OBTENER_TEXTOS_AYUDA = 'OBTENER_TEXTOS_AYUDA'

export const OBTENER_BENEFICIOS_NIVELES = 'OBTENER_BENEFICIOS_NIVELES'

export const SET_TUTORIAL_FALSE = 'SET_TUTORIAL_FALSE'
export const GET_HOME = 'GET_HOME'

export const GET_BENEFICIOS_COMUNIDADES = 'GET_BENEFICIOS_COMUNIDADES'

export const OBTENER_PROMOCIONES = 'OBTENER_PROMOCIONES'
export const OBTENER_PROMOCION = 'OBTENER_PROMOCION'
export const LIMPIAR_PROMOCION_ID = 'LIMPIAR_PROMOCION_ID'

export const GET_INTERESES = 'GET_INTERESES'
export const GET_CATEGORIAS = 'GET_CATEGORIAS'
export const UPDATE_INTERES = 'UPDATE_INTERES'
export const UPDATE_CATEGORIAS = 'UPDATE_CATEGORIAS'