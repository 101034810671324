import { GET_CATEGORIAS, GET_INTERESES, MENSAJE_USUARIO_ERROR, RESETEAR_CARGANDO, UPDATE_CATEGORIAS, UPDATE_INTERES } from "../../types";

const preferenciasReducer = (state, action) => {
    switch (action.type) {
        case MENSAJE_USUARIO_ERROR:
            return {
                ...state,
                mensaje: action.payload,
                loadingPreferencias: false,
                intereses: [],
                categorias: []
            }
        case GET_INTERESES:
            return {
                ...state,
                intereses: action.payload,
                loadingPreferencias: false
            }
        case GET_CATEGORIAS:
            return {
                ...state,
                categorias: action.payload,
                loadingPreferencias: false
            }
        
        case UPDATE_INTERES:
        case UPDATE_CATEGORIAS:
            return {
                ...state,
                loadingPreferencias: false
            }
        case RESETEAR_CARGANDO:
            return {
                ...state,
                loadingPreferencias: true
            }
        default:
            return state;

    }
}

export default preferenciasReducer