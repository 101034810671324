import react from "react";
import { MENSAJE_ERROR, PINTAR_REPUESTA_CONCURSO,OBTENER_CONCURSO,MODAL_CONCURSOS_RESPUESTAS,OBTENER_FOTOS_PARA_VOTAR, OBTENER_CONCURSO_PREGUNTAS,CONCURSOS_BANNER_ITEM,CONCURSOS_GANADORES , RESETEAR_CARGANDO,RESETEAR_MENSAJE} from '../../types/index';

export default (state,action) => {
    switch(action.type){
        case MENSAJE_ERROR:
            return{
                ...state,
                mensajeConcursos: action.payload,
                cargandoConcursos: false
            }
        case PINTAR_REPUESTA_CONCURSO:
            return{
                ...state,
                pintarRespuesta:action.payload,
                cargandoConcursos:false
            }
        case OBTENER_CONCURSO:
                return{
                    ...state,
                    concurso: action.payload,
                    cargandoConcursos:false
                }
        case OBTENER_CONCURSO_PREGUNTAS:
            return{
                ...state,
                concursoCompleto: action.payload,
                cargandoConcursos:false
            }
        case MODAL_CONCURSOS_RESPUESTAS:
            return{
                ...state,
                mostrarModalConcursos: action.payload,
                cargandoConcursos:false
            }
        case OBTENER_FOTOS_PARA_VOTAR:
            return{
                ...state,
                fotosParaVotar: action.payload,
                cargandoConcursos:false
            }
        case CONCURSOS_BANNER_ITEM:
            return{
                ...state,
                concursoBanner:action.payload,
                cargandoConcursos:false
            }
        case CONCURSOS_GANADORES:
            return{
                ...state,
                listadoGanadores:action.payload,
                cargandoConcursos:false
            }
        case RESETEAR_CARGANDO:
            return {
                ...state,
                cargandoConcursos: true,
            }
        case RESETEAR_MENSAJE:
            return {
                ...state,
                mensajeConcursos:null
            }
    }
}