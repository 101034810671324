import {
    MENSAJE_ERROR,
    RESETEAR_CARGANDO,
    OBTENER_PROMOCIONES,
    OBTENER_PROMOCION,
    LIMPIAR_PROMOCION_ID
} from '../../types'

const promocionesReducer = (state, action) => {
    switch (action.type) {
        case MENSAJE_ERROR:
            return {
                ...state,
                mensaje: action.payload,
                cargandopromociones: false
            }
        case RESETEAR_CARGANDO:
            return {
                ...state,
                cargandopromociones: true,
            }
        case OBTENER_PROMOCIONES:
            return {
                ...state,
                promociones: action.payload,
                cargandopromociones: false
            }
        case OBTENER_PROMOCION:
            return {
                ...state,
                promocion: action.payload,
                cargandopromociones: false
            }
        case LIMPIAR_PROMOCION_ID:
            return {
                ...state,
                promociones: {
                    ...state.promociones,
                    popUpPromocionId: null
                }
            }
        default:
            return state;
    }
}

export default promocionesReducer